export const randomNumber = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
} 


const alphabet = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
export const randomString = (length) => {
    if (!length) {
        length = 5
    }
    return Array(length).join().split(',').map(function() { return alphabet.charAt(Math.floor(Math.random() * alphabet.length)); }).join('');
}