import { Button, ButtonProps } from '@mui/material';
import { useTranslation } from 'next-i18next';
import { FC, ReactNode } from 'react';

export const DialogActionCloseButton: FC<{ onClick:(e:any)=>void, children?: ReactNode } & ButtonProps> = ({ onClick, children, ...props }) => {

    const { t } = useTranslation('common')

    return (
        <Button
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
            variant={"text"}
            onClick={onClick}
            {...props}
            >
            {children??t('action.done')}
        </Button>
    );
}