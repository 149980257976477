import { getObject, saveObject } from '../clientSideStore/clientSideStore';
import { notNullNotUndefined } from '../../../../utils/objectUtils';

const sessionId = 'handbag';

const getSession = () => getObject(sessionId);
const saveSession = (session) => saveObject(sessionId, session);

/**
 * 
 * @param {*} field 
 * @param {*} value 
 * @deprecated
 */
export const saveSessionFieldLegacy = (field, value) => {
  notNullNotUndefined(value);
  let currentSession = getSession() || {};
  currentSession[field] = value;
  console.debug('saving session field ' + field + ', value: ', value);
  saveSession(currentSession);
}

/**
 * Returns undefined if there is no session.
 * Returns undefined if session field does not exist.
 * May return null if the session field value is null.
 *
 * @param {} field
 * @param {*} value
 * @deprecated
 */
export const getSessionFieldLegacy = (field) => {
  let currentSession = getSession();
  if (!currentSession) {
    return undefined;
  }
  return currentSession[field];
};
