import { saveSessionFieldLegacy } from 'modules/yoio-modules/storage/cupboard';
import { useState, useEffect } from 'react';
import { useSignedIn } from 'modules/react-auth/index';
import { saveAccessEvent, saveAccessEventUser } from 'modules/yoio/api/accessApi';
import { getQueryParamSafe, getWindowLocationHrefSafe } from '../../../utils/windowUtils';
import { useYoioContext } from 'modules/yoio/useYoioContext';
import { useApp } from 'modules/yoio/useApp';
import { useIsMounted } from 'modules/picasso-ui/form/useIsMounted';
import { isHavingValue, notNullNotUndefined } from 'utils/objectUtils';
import { featureDetectsStorageReport } from 'utils/featureDetects';
import { randomString } from 'utils/randomUtils';
import { notify } from '../errorsService';
import { getFromSessionStorageById } from 'modules/yoio-modules/storage/clientSideStore/clientSideStore';
import { YoioSessionStorageFields } from '../model/YoioHttpRequestFields';
import { sanitizeLocationUrl } from 'modules/yoio-modules/core/analytics/analyticsUtils';

const pathId = randomString(8)

export const useAnalytics = () => {

  const { signedIn, isUsingYoioToken, me } = useSignedIn()

  const { pageSettings, pageMeta } = useYoioContext()

  const { app } = useApp()

  const [trackingEnabled, setTrackingEnabled] = useState(null)
  const [consentRequired, setConsentRequired] = useState(false)

  const isMounted = useIsMounted()

  useEffect(() => {
    try {
      
      if (pageSettings.embedded === true) {
        //if embedded, never show consent and never enable direct tracking
        return;
      }
  
      if (signedIn === true) {
        setTrackingEnabled(true);
      } else {
        let consent = null;
  
/*         if (isCookiesSupported()) {
          try {
            consent = getSessionFieldLegacy('Consent');
          } catch (e) {
            console.error('error reading consent')
            console.error(e)
          }
        } */
  
        if (isHavingValue(consent)) setConsentRequired(true);
        if (consent === true) {
          setTrackingEnabled(true);
        }
      }

    } catch (error) {
      console.error(error)
    }

  }, [signedIn]);

  const buildEventDataBase = () => {
    const data = {
      location: getWindowLocationHrefSafe(),
    }

    if (data.location) {
      try {
        data.location = sanitizeLocationUrl(data.location)
      } catch (e) {
        console.error(e)
      }
    }

    if (pageMeta?.referrer && pageMeta?.internal !== true) {
      data.ref = pageMeta.referrer
    } 
    else if (document?.referrer?.length > 0) {
      data.ref = document.referrer
    }
    if (document?.title) {
      data.dt = document.title
    }
    if (window?.screen?.availWidth && window?.screen?.availHeight) {
      data.sr = window.screen.availWidth+'x'+window.screen.availHeight
    }

    return data
  }

  const trackEvent = ({action}) => {
    try {
      if (app.useApi !== true) {
        return;
      }

      notNullNotUndefined(action)

      const data = buildEventDataBase()
      data.action = action
      saveAccessEvent(pathId, data)
    } catch (error) {
      console.log(error)
    }
  }

  return {
    trackingEnabled,
    trackPageViewed: () => {

      try {

        if (app.useApi !== true) {
          return;
        }

        setTimeout(()=>{ //hack, wait until yoioContext shared in window

          try {
            if(isMounted()) {
              
              try {
                if (isUsingYoioToken || isHavingValue(getQueryParamSafe('yoioToken')) || isHavingValue(getFromSessionStorageById(YoioSessionStorageFields.ACCESS_YT))) {
                  return;
                }
              } catch (error) {
                notify(error)
              }

              const data = buildEventDataBase()
              saveAccessEvent(pathId, data)
            }
          } catch (e2) {
            console.error(e2)
          }
          
        }, 1000)
      } catch (error) {
        console.error(error)
      }
    
      return;
      //gtag.pageview(path);
    },
    trackEvent,
    trackIfStorageNotSupported: () => {
      try {
        const report = featureDetectsStorageReport()

        if (report.errors) {
          trackEvent({action:'featureNotSupportedStorage'+errors[0]})
        }
      } catch (error) {
        console.error(error)
      }
    },
    consentRequired,
    saveConsent: (consent) => {
      setConsentRequired(false);
      if (consent === true) {
        setTrackingEnabled(true);
      }
      try {
        saveSessionFieldLegacy('Consent', consent);
      } catch (error) {
        console.log('error saving consent')
      }
    },
    trackAccessEventUser: (action) => {
      try {
        if (app.useApi !== true) {
          return;
        }

        notNullNotUndefined(action)

        //app internal - no consent required

  
        if(isMounted()) {
          const data = buildEventDataBase()
          data.action = action
          saveAccessEventUser(pathId, data)
        }
      } catch (error) {
        console.log(error)
      }

    }
  };
};
