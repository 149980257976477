export const sanitizeLocationUrl = (url:string):string => {
    if (!url) {
        return null
    }
    let sanitized = url

     // Blur mkey because it is sensible
    if (sanitized.includes('/admin/')) {
        sanitized = sanitized.replace(new RegExp('/admin/(.*)', 'g'), '/admin/{mkey}')
    }

    // Blur tempId because it is irrelevant and destroys grouping same page
    if (sanitized.includes('tempId=')) {
        let params = getParamsFromStr(sanitized)
        sanitized = sanitized.replace('tempId='+ params.tempId, 'tempId={tempId}')
    }
    return sanitized 
}

const getParamsFromStr = (str) =>{
    return str.replace('?', '&')
      .split('&')
      .map(param => param.split('='))
      .reduce((values, [ key, value ]) => {
        values[ key ] = value
        return values
    }, {})
}