import { Box, BoxProps, Button, Typography, lighten, snackbarContentClasses } from "@mui/material";
import { Snackbar } from "@mui/material";
import { ElementLink } from "modules/picasso-ui/link/LinkNormal";
import { FC, PropsWithChildren, useState } from "react";
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from "@mui/material";
import { useAnalytics } from "modules/yoio/analytics/useAnalytics";
import { DeferVisibility } from "modules/picasso-ui/present/status/DeferVisibility";
import CheckIcon from '@mui/icons-material/Check';
import { VerticalAlignBox } from "modules/picasso-ui/layout/VerticalAlignBox";
import { InfoTooltip } from "components/present/InfoTooltip";

export interface NotificationSnackbarProps {
    style?: any, onDismiss?: ()=>void
}

export const NotificationSimple: FC<PropsWithChildren<NotificationSnackbarProps>> = ({children, onDismiss, style}) => {
    const [open, setOpen] = useState(true)

    const handleClose = (event: React.SyntheticEvent | MouseEvent, reason?: string) => {
      if (reason === 'clickaway') {
        return;
      }
      setOpen(false);
      onDismiss?.()
    };
  
    return (
      <div>
        <Snackbar 
          open={open} 
          onClose={handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} 
          sx={{
              maxWidth: 'none',
            [`& .${snackbarContentClasses.root}`]: {
                overflow: 'hidden',
              backgroundColor: 'white',
              width: 500, 
              height: 500,
              padding: 2, 
              boxShadow: '0 0 40px -10px rgba(170,176,196,.45)',
              borderRadius: '14px',
              position: 'relative',
              ...style
            },
            [`& .${snackbarContentClasses.message}`]: {
                overflow: 'hidden',
                padding: 0,
                display: 'block',
                height: '100%',
                width: '100%',
                color: 'text.primary',
            }
          }}
          message={<>
            <div style={{position: 'absolute', right: '10px', top: '10px'}}>
                <DeferVisibility timeoutMs={3000}><IconButton onClick={(e)=>handleClose(e, null)} size="small"><CloseIcon sx={{color: 'rgb(166 166 166 / 34%)'}} /></IconButton></DeferVisibility>
            </div>
            {children??<span>Custom content</span>}</>}
        />
      </div>
    );
  }

const NotificationReview = () => {
    return <NotificationSimple style={{ paddingLeft: 0, paddingRight: 0, padding: 0 }}>
        <div css={{
            background: 'linear-gradient(to top, #5A67D8 2%, #6A7AFF 90%)',
            height: '100%', position: 'relative'}}>
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100%">


{/*                 <Absolute style={{width: '100%', height: '100%', display: 'flex', alignItems: 'center'}} center>
                    <img src="/app-prioneer/notifications/6511d952fa1d29a825700bc4-istockphoto-1268217711-1024x1024.jpg" width="100%" height="auto" />
                </Absolute> */}

                <Box position="relative" display="flex" flexDirection="column" alignItems="center">
                    <Typography color="#fff" fontSize="35px" fontWeight="400" mb={1}>Write a review. Get 20$</Typography>
                    <img src="/app-prioneer/notifications/6511d952fa1d29a825700bc4-review-stars-3.png" width="310px" />
                </Box>

                <Box mt={2} mb={6} width="100%" pl={6} pr={6} textAlign="center">

                    <Typography color="#2e3570" py={2} style={{opacity:1, fontWeight: 600, fontSize: '16px' }}>Get a $20 Gift Card for telling about your experience with Prioneer.io</Typography>

                    <Button 
                        sx={{ 
                            borderRadius: '8px', 
                            fontSize: '20px',
                            background: '#0cc38f !important',
                            '&: hover': {
                                background: `${lighten('#0cc38f', 0.2)} !important`,
                            }

                            //color: 'rgb(254 254 254 / 29%) !important', 
                            //background: 'none !important', 
                            //border: '2px solid rgb(254 254 254 / 29%) !important' 
                        }} 
                        size="extraLarge" variant="contained" fullWidth>
                            Write review
                    </Button>
                </Box>

                <Box width="100%" sx={{background: '#fff'}} display="flex" justifyContent="center" py={1}>
                    <Typography component="span" fontSize="18px" fontWeight="700">5 Minutes = 20$ Gift card</Typography>
                </Box>
            </Box>
        </div>
    </NotificationSimple>
}

const NotificationReview2 = (props:PropsWithChildren<NotificationSnackbarProps>) => {

    return <NotificationSimple style={{ padding: '40px 60px', width: '600px', height: '350px' }} {...props}>
        <NotificationReview2Content />
    </NotificationSimple>
}

const NotificationReview2Content = () => {
    
    const { trackAccessEventUser } = useAnalytics()

    return (
        <div css={{
            //background: 'linear-gradient(to top, #5A67D8 2%, #6A7AFF 90%)',
            height: '350px',
            position: 'relative'}}>
            <Box display="flex" flexDirection="column" alignItems="stretch" justifyContent="center">
                <Box position="relative" display="flex" flexDirection="row" alignItems="flex-start" justifyContent="space-between">
                    <Box>
                        <Typography fontSize="14px" fontWeight="600" mb={2} color="#0cc38f" style={{ letterSpacing: '1px' }}>What's your opinion on Prioneer.io?</Typography>
                        <Typography fontSize="31px" fontWeight="600" mb={2} style={{ lineHeight: '1.2' }}>Write a review.<br/>Get 20$</Typography>

                        <Typography color="text.secondary" mb={2} style={{opacity:1, fontWeight: 500, fontSize: '15px' }}>Get a $20 Gift Card for telling about your experience with Prioneer.io</Typography>
                    </Box>
                    <div style={{justifyContent: 'flex-end', width: '200px', paddingTop: '80px'}} 
                        css={theme=>({
                            display: 'flex', 
                            [theme.breakpoints.down('sm')]:{display: 'none'}
                            })
                        }
                    >
                        <img src="/app-prioneer/notifications/6511d952fa1d29a825700bc4-review-stars-3.png" width="200" height="68" 
                            style={{
                                objectFit: 'cover',
                                overflow: 'hidden',
                                objectPosition: 'top left'
                            }}
                        />
                     </div>
                </Box>

                <Box width="100%" mt={1}>

                    <Box display="flex" sx={theme=>({ [theme.breakpoints.up('sm')]:{'&>*': { flexBasis: '50%' }} })}>
                        <ElementLink disableClientSideNav href="https://reviews.capterra.com/new/351851/0f9c9b61-f35f-483c-84ad-438643799842" target="_blank" rel="noreferrer" onClick={()=>trackAccessEventUser('write review clicked')}>
                            <Button
                                sx={{ 
                                    fontSize: '20px',
                                    background: '#0cc38f !important',
                                    '&: hover': {
                                        background: `${lighten('#0cc38f', 0.2)} !important`,
                                    },
                                    borderRadius: '99px',
                                    //color: 'rgb(254 254 254 / 29%) !important', 
                                    //background: 'none !important', 
                                    //border: '2px solid rgb(254 254 254 / 29%) !important' 
                                }} 
                                size="extraLarge" variant="contained" fullWidth>
                                    Write review
                            </Button>
                        </ElementLink>
                        <Box display="flex" alignItems="center" ml={3} sx={theme=>({ [theme.breakpoints.down('sm')]:{ display: 'none' }})}>
                            <Typography component="span" fontSize="14px" fontWeight="500" lineHeight="1.2">
                                5 Minutes = 20$ Gift Card.<br/>
                                The first 95 reviewers qualify for a gift card.

                            </Typography>
                        </Box>
                    </Box>
                </Box>

{/*                 <Box width="100%" sx={{background: '#fff'}} display="flex" justifyContent="center" py={1}>
                   
                </Box> */}
            </Box>
        </div>
    )
}

export const NotificationReviewBanner: FC<BoxProps & {slideDownOnHover?: boolean, subtitleStyle?: any}> = ({slideDownOnHover, subtitleStyle, ...props}) => {
    
    const { trackAccessEventUser } = useAnalytics()

    return <Box 
            padding="32px" 
            {...props} 
            sx={[
                { 
                    background: 'radial-gradient(100.97% 140.09% at 13.29% 100%, rgba(0, 196, 204, 0.725) 0%, rgb(0, 196, 204) 0.01%, rgba(0, 196, 204, 0) 100%), radial-gradient(53.32% 104.09% at 30.22% 82.12%, rgb(100, 32, 255) 0%, rgba(100, 32, 255, 0) 100%), radial-gradient(93.59% 106.82% at 0% 100%, rgb(0, 196, 204) 0%, rgba(0, 196, 204, 0) 85.18%), radial-gradient(109.54% 100.54% at 0.77% 50.34%, rgb(100, 32, 255) 0.79%, rgba(100, 32, 255, 0) 100%), rgb(125, 42, 231)',
                    borderRadius: '12px',
                    //height: '200px',
                    width: '400px',
                    maxWidth: '100%',
                }, 
                ...(Array.isArray(props.sx) ? props.sx : [props.sx]),
                slideDownOnHover ? {
                    position: 'relative',
                    transition: 'max-height 0s ease-in',
                    maxHeight: '220px',
                    overflow: 'hidden',
                    '& .bulletpoints': {
                        visibility: 'hidden'
                    },
                    '&:hover': {
                        transition: 'max-height 0.7s ease-in',
                        maxHeight: '600px',
                        '& .bulletpoints': {
                            visibility: 'visible'
                        }
                    },
/*                     '& .curtain': {
                        transition: 'max-height 0.7s ease-in',
                        position: 'absolute',
                        bottom: 0,
                        left:0,
                        width: '100%',
                        maxHeight: '250px',
                        height: '100%',
                        background: '#fff',
                        overflow: 'hidden',
                    },
                    '&:hover .curtain': {
                        maxHeight: '0px',
                    }, */
                } : null
            ]}
            >
        <VerticalAlignBox>
            <Typography color="#fff" fontSize="2em" fontWeight="600">Your Story Matters.</Typography>
            <svg clip-rule="evenodd" fill-rule="evenodd" width="48" height="48" image-rendering="optimizeQuality" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" viewBox="0 0 1707 1707" xmlns="http://www.w3.org/2000/svg" id="fi_7334113"><g id="Layer_x0020_1"><path d="m1128 1066c-312 0-564-253-564-564 0 311-253 564-564 564 311 0 564 252 564 563 0-311 252-563 564-563z" fill="#ffd93b"></path><g fill="#ffb030"><path d="m1707 1357c-171 0-309-139-309-309 0 170-139 309-309 309 170 0 309 138 309 309 0-171 138-309 309-309z"></path><path d="m1529 417c-208 0-376-168-376-376 0 208-168 376-376 376 208 0 376 168 376 376 0-208 168-376 376-376z"></path></g></g></svg>
        </VerticalAlignBox>

        <Typography color="#fff" fontSize="1.07em" fontWeight="400" mb={3} sx={[{opacity: '0.7',},subtitleStyle]}>
            Choosing the right software can be difficult.
            Help others to reduce uncertainty by writing a review for Prioneer.io.
        </Typography>

{/**
 *  Is Prioneer.io a good choice? 
 * "Choosing software can be filled with uncertainty.""
 * "Write a review for Prioneer.io and help others to reduce uncertainty."
 */}
       {/*  <Typography color="#fff" fontSize="14px" fontWeight="400">Write a review, so you help other's to reduce uncertainty when they choose a software.</Typography> */}

{/*             Reviews can help reduce uncertainty 
        <Typography color="#fff" fontSize="14px" fontWeight="400">Help other's to find the right software.</Typography> */}
        <ul className="bulletpoints" css={{ paddingLeft: '20px', color: '#fff', '& li': { margin: '10px 0', fontWeight: 700, fontSize: '1.07em' }}}>
            <li>Share your experience</li>
            <li>Get a $20 gift card</li>
            <li>Shape another's journey</li>
        </ul>

        <Box mt="3.8em">
            <ElementLink disableClientSideNav href="https://reviews.capterra.com/new/351851/0f9c9b61-f35f-483c-84ad-438643799842" target="_blank" rel="noreferrer" onClick={()=>trackAccessEventUser('write review clicked in banner')}>
                <Button
                    sx={{ 
                        fontSize: '1.43em',
                        background: `${lighten('#0cc38f', 0.2)} !important`,
                        '&: hover': {
                            background: '#0cc38f !important',
                        },
                        borderRadius: '99px',
                        padding: '0.7em 1em',
                        //color: 'rgb(254 254 254 / 29%) !important', 
                        //background: 'none !important', 
                        //border: '2px solid rgb(254 254 254 / 29%) !important' 
                    }} 
                    size="extraLarge" variant="contained" fullWidth>
                        Write review
                </Button>
            </ElementLink>
        </Box>
        <VerticalAlignBox gap="4px" mt={1} justifyContent="center"><CheckIcon sx={{fontSize: '14px !important', color: '#fff', }} /><Typography color="#fff" fontSize="0.9em" fontWeight="300">Anonymous review possible</Typography></VerticalAlignBox>

        <div className="curtain"></div>
     </Box>
}

export const notificationsRenderer = {
    '6511d952fa1d29a825700bc4': NotificationReview2,
    '652e8578e124424588c28dc4': NotificationReviewBanner,
    standard: NotificationSimple
}