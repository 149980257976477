import { useRouter } from "next/router";

export const useRouterUtils = () => {

    const router = useRouter();

    const getCurrentAsPathClean = () => {
        return router.asPath.split('?')[0]
    }

    const pushQueryParams = (updated) => {
        router.push({
            query: updated,
            pathname: getCurrentAsPathClean(),
        });
    }

    const pushAdditionalQueryParams = (additional) => {
        pushQueryParams({ 
            ...router.query,
            ...additional
        });
    }

    const deleteQueryParam = (key) => {
        deleteQueryParams([key])
    }

    const deleteQueryParams = (keys) => {
        if (router.query) {
            
            let updatedQuery = {...router.query};

            let deleted = false

            keys.forEach(key=>{
                if (updatedQuery[key]) {
                    deleted = true
                }
                delete updatedQuery[key];
            })

            if (!deleted) {
                //nothing to do
                return;
            }

            try {
                //this is a bit hacky.
                //it makes sure to remove query params that are at the same time path keys.
                //otherwise path params suddenly appear as real query params after push
                //for some annoying reason ots not possible to cleanly remove ONLY REAL QUERY PARAMS from q next js route
                const urlSearchParams = new URLSearchParams(window.location.search);
                const realVisibleParams = Object.fromEntries(urlSearchParams.entries());
    
                Object.keys(updatedQuery).forEach(queryParam=>{
                    if (!realVisibleParams[queryParam]) {
                        delete updatedQuery[queryParam];
                    }
                })
            } catch (error) {
                console.error(error)
            }

            pushQueryParams(updatedQuery);
        }
    }

            /**
         * If removeList is empty, the function removes all params from url.
         * @param {*} removeList 
         */
        const deleteQueryParamsV2 = (removeList = [], sanitizeSubDir) => {
            if (removeList.length > 0) {
                removeList.forEach((param) => delete router.query[param]);
            } else {
                // Remove all
                Object.keys(object).forEach((param) => delete object[param]);
            }

            let pathnameEffective = null;

            if (sanitizeSubDir) {
                pathnameEffective = router.pathname.replace(sanitizeSubDir, '')
            } else {
                pathnameEffective = router.pathname
            }
            

            router.replace(
                {
                    pathname: pathnameEffective,
                    query: router.query
                },
                undefined,
                /**
                 * Do not refresh the page
                 */
                { shallow: true }
            );
        };

    return { pushQueryParams, pushAdditionalQueryParams, deleteQueryParam, deleteQueryParams, deleteQueryParamsV2 }

}