import { getAxiosBackground } from "apiClient/config/apiAxiosConfig"
import { ReactNode, useEffect } from "react"
import useSWR, { useSWRConfig } from "swr"
import { isNullOrUndefined, notNullNotUndefined } from "utils/objectUtils"
import { handleResponseDataGetContent } from "utils/useResolvedV2"
import { notificationsRenderer } from "./NotificationsRenderer"
import { clientSideState } from "./clientSideState"
import { useErrorNotify } from "modules/yoio/errorsService"
import { dismissNotification as saveDismissNotification } from "modules/yoio/api/accessApi"

const getChannelFetchKey = (channel:string) => {
    notNullNotUndefined(channel)
    return `/ui/notifications/${channel}`
}

export const useNotifications = () =>{

    const { notify } = useErrorNotify()

    const { mutate } = useSWRConfig()

    /**
     * 
     * @param notificationId 
     * @param channel 
     *      Notification will only appear on components that listen to the channel.
     */
    const showNotificationInUi = (notificationId: string, channel: string) => {
        try {
            notNullNotUndefined(notificationId)
            notNullNotUndefined(channel)

            clientSideState[channel] = notificationId
            mutate(getChannelFetchKey(channel))
        } catch (error) {
            notify(new Error(`error: can't set the notification to show. notificationId:${notificationId} channel:${channel}`))
        }

    }

    const dismissNotification = (notificationId: string, channel: string):Promise<any> => {
        notNullNotUndefined(notificationId)
        notNullNotUndefined(channel)

        try {
            return saveDismissNotification(notificationId).then(()=>{
                clientSideState[channel] = null
                mutate(getChannelFetchKey(channel))
            })
        } catch (error) {
            notify(new Error(`error: notification dismiss. notificationId:${notificationId} channel:${channel}`))
            clientSideState[channel] = null
            mutate(getChannelFetchKey(channel))
            return Promise.resolve()
        }

    }

    return { showNotificationInUi, dismissNotification }
    
}

export const NotificationsDisplayWidget = ({channel, render}:{channel:string, render?: ((notificationId: string, message?: string)=>ReactNode|boolean)}) => {

    const { data: notificationId } = useSWR(channel?getChannelFetchKey(channel):null,()=>clientSideState[channel]??undefined)

    const { data } = useSWR(notificationId ? `/apps/current/notifications/${notificationId}` : null, ()=>getAxiosBackground().get(`/apps/current/notifications/${notificationId}`).then(handleResponseDataGetContent),
        {
            revalidateIfStale: false,
            revalidateOnFocus: false,
            revalidateOnReconnect: false,
        }
    )

    const { dismissNotification } = useNotifications()

    let node: ReactNode = null
    if (notificationId && data?.notificationId === notificationId) {
        
        let renderResult = null
        if (render) {
            renderResult = render(data.notificationId, data.message)
            if (renderResult !== true && renderResult !== false) {
                node = renderResult;
            }
        } 

        if (!render || renderResult === true) {
            const DisplayComponent = notificationsRenderer[notificationId] ?? notificationsRenderer.standard
            node = <DisplayComponent onDismiss={()=>dismissNotification(notificationId, channel)} />
        }
    }

    return <>{node}</>
}

/**
 *  Loads notifications from API and triggers to show in UI.
 */
export const NotificationsLoader = ({targetChannel, userId}) => {

    const { showNotificationInUi } = useNotifications()

    const { data: pendingNotifications } = useSWR<{notificationId: string, channels?: string[]}[]>(userId ? `/access/me/notifications/pending?targetChannel=${targetChannel}` : null, ()=>getAxiosBackground().get(`/access/me/notifications/pending?channel=${targetChannel}`).then(handleResponseDataGetContent),
        {
            revalidateIfStale: false,
            revalidateOnFocus: true,
            revalidateOnReconnect: false,
            focusThrottleInterval: 120000
        }
    )

    useEffect(()=>{
        if (isNullOrUndefined(pendingNotifications)) { return; }
        if (pendingNotifications.length === 0) { return; }

        for (const notification of pendingNotifications) {
            if (notification.channels?.includes(targetChannel)) {
                showNotificationInUi(notification.notificationId, targetChannel)
            }
        }
    },[pendingNotifications])

    return null

}