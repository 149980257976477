import PropTypes from 'prop-types';
import { blue } from '@mui/material/colors';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  Box,
  Typography,
  Divider,
  IconButton,
  Button,
  ButtonProps,
  Theme,
  BackdropProps,
  PaperProps,
  Breakpoint,
  dialogClasses,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { DialogActionCloseButton } from './DialogActionCloseButton';
import { EntityName } from '../present/EntityName';
import { useEffect, useRef, useState } from 'react';
import { MultilineText } from 'modules/picasso-ui/present/MultilineText';
import { isFunction, isHavingValue, orElse } from 'utils/objectUtils';
import { useRouter } from 'next/router';
import CloseIcon from '@mui/icons-material/Close';
import { useRouterUtils } from 'utils/routerUtils';
import { isString } from 'utils/stringUtils';
import { ReactNode } from 'react';
import { FunctionComponent } from 'react';


export interface SimpleDialogProps {
  open: boolean
  title?: ReactNode | string, 
  titleTextAlign?: string,
  titleNoDivider?: boolean,
  subtitle?: ReactNode, 
  // eslint-disable-next-line no-unused-vars
  onClose?: (event: {}, reason: "backdropClick" | "escapeKeyDown" | "submit" | "cancelClick") => void
  children?: ReactNode  | (() => ReactNode)
  entity?: {type?: string, name?: string}
  fullWidth?: boolean
  fullScreen?: boolean
  maxWidth?: false | Breakpoint
  height?: string
  contentNoPadding?: boolean
  hideActions?: boolean
  // eslint-disable-next-line no-unused-vars
  actionsComponent?: (handleClose:(event: {}, reason: "backdropClick" | "escapeKeyDown" |  "submit" | "cancelClick") => void)=>ReactNode
  actionsNoDivider?: boolean
  fullWidthDoneButton?: boolean
  doneButtonText?: string
  incentiveTitle?: string, 
  incentiveSubtitle?: string, 
  TransitionComponent?: any
  positionTop?: boolean
  positionTopVeryClose?: boolean
  positionPaddingTop?: string
  headerContentEnd?: ReactNode
  showFullScreenCloseButton?: boolean
  showTitleCloseButton?: boolean
  showCloseButton?: boolean
  paddingSmall?: string
  sx?: any
  container?: Element | (() => Element | null) | null
  BackdropProps?: BackdropProps
  style?:any
  scroll?: 'body' | 'paper'
  noScroll?: boolean
  disableEnforceFocus?: boolean
  PaperProps?: PaperProps
  closeForbidden?: boolean
  closeBackdropClickForbidden?: boolean
  /**
   * If true, dialog will behave better on small screens, e.g. being fullWidth
   */
  responsive?: boolean
  actions?: { title: string, value: 'submit', color?: string} []
  actionsStyle?: any
}

const useStyles = makeStyles((theme: Theme) => ({
  scrollPaperTop: {
    // No effect beneath md
    [theme.breakpoints.up('md')]: {
      alignItems: 'baseline',
      paddingTop: '10vh',
    }
  },
  scrollPaperTopVeryClose: {
    alignItems: 'baseline',
    paddingTop: '70px', //only app bar
  },
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
  title: {
    fontSize: '22.4px',
    fontWeight: '500',
  },
  dialogPaper: {
    minHeight: '80vh',
    maxHeight: '80vh',
  },
  dialogTitle: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  dialogTitleWithDivider: {
    paddingBottom: theme.spacing(3),
  },
  dialogContent: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(3),
  },
  dialogPaddingDefault: {
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3)
  },
  dialogPaddingSmall: {
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2)
  },
  dialogPaddingNoHorizontal: {
    paddingTop: 0,
    paddingLeft: 0,
    paddingRight: 0,
    paddingBottom: 0,
  },
  dialogContentAfterDivider: {
    paddingTop: theme.spacing(3),
  },
  incentiveTitle: {
    fontSize: '1.8rem',
    fontWeight: '400',
  },
  incentiveSubtitle: {
    color: theme.palette.text.secondary,
    fontSize: '16px',
    fontWeight: '300',
  },
  dialogActions: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  }
}));

const styles = {
  paper: theme=>({
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingRight: 0,
      marginLeft: 0,
      marginRight: 0,
      marginBottom: 0,
      marginTop: 0,
    }
  })
}

/**
 * Simple Dialog.
 * 
 * Bei Clickaway und Click auf "Done" passiert das gleiche.
 * Clickaway ist also kein "Abbrechen".
 * 
 * @param {*} param0 
 * @returns 
 */
export const SimpleDialog: FunctionComponent<SimpleDialogProps> = ({ 
  title, titleTextAlign, titleNoDivider,
  subtitle,
  onClose, open, children, 
  entity, 
  fullWidth, fullScreen, maxWidth, height, 
  contentNoPadding, 
  hideActions,
  actionsComponent,
  actionsNoDivider,
  fullWidthDoneButton,
  doneButtonText,
  incentiveTitle, incentiveSubtitle,
  TransitionComponent, 
  positionTop, 
  positionTopVeryClose, 
  positionPaddingTop,
  headerContentEnd,
  showFullScreenCloseButton,
  showTitleCloseButton,
  showCloseButton,
  paddingSmall,
  sx,
  container,
  BackdropProps,
  style,
  scroll,
  noScroll,
  disableEnforceFocus,
  PaperProps,
  closeForbidden,
  closeBackdropClickForbidden,
  responsive,
  actions,
  actionsStyle,
  }) => {
  const classes = useStyles();

  const handleClose = (event: {}, reason: "backdropClick" | "escapeKeyDown" | "submit" | "cancelClick") => {
    if (closeForbidden) {
      return;
    }
    if (closeBackdropClickForbidden && reason === 'backdropClick') {
      return;
    }
    onClose(event, reason)
  }

  // glue code. ideally there would be additional reasons such as 'done clicked' etc.
  const handleCloseWithoutReason = () => {
    handleClose(null, null)
  }

  const dialogStyleClasses = {} as any;

  const dialogPaperClassNames = [];
  if (height === 'l') {
    dialogPaperClassNames.push(classes.dialogPaper);
  }

  if (positionTop === true) {
    dialogStyleClasses.scrollPaper = classes.scrollPaperTop
  }

  if (positionTopVeryClose === true) {
    dialogStyleClasses.scrollPaper = classes.scrollPaperTopVeryClose
  }

  dialogStyleClasses.paper = dialogPaperClassNames.join(' ');


  const showDefaultTitleElem = isHavingValue(entity) || isHavingValue(title)
  const hasDividerAfterTitle = showDefaultTitleElem === true && titleNoDivider !== true 

  const titleClasses = [classes.dialogTitle]
  if (hasDividerAfterTitle) {
    titleClasses.push(classes.dialogTitleWithDivider)
  }


  if (paddingSmall) {
    titleClasses.push(classes.dialogPaddingSmall)
  } else {
    titleClasses.push(classes.dialogPaddingDefault)
  }

  const contentClasses = [classes.dialogContent];

  if (contentNoPadding === true) {
    contentClasses.push(classes.dialogPaddingNoHorizontal);
  } 
  else if (paddingSmall) {
    contentClasses.push(classes.dialogPaddingSmall);
  }
  else {
    contentClasses.push(classes.dialogPaddingDefault);
  }

  if (hasDividerAfterTitle) {
    contentClasses.push(classes.dialogContentAfterDivider)
  }

  const showIncentiveTitle = (incentiveTitle?.length > 0 || incentiveSubtitle?.length > 0) && !showDefaultTitleElem

  const dialogTitleStyle = {textAlign: titleTextAlign || 'left'} as any

  const renderTitleCloseButton = (margin) => {

    margin = margin || '-5px'

    return (
      <Box display="flex" alignItems="center" position="absolute" right={margin} top={margin}>
        <IconButton onClick={handleCloseWithoutReason} size="small">
          <CloseIcon sx={{color: 'rgb(166 166 166 / 54%)'}} />
        </IconButton>
      </Box>
    )
  }

  if (sx) {
    sx = Array.isArray(sx) ? sx : [sx]
  }

  if (positionPaddingTop) {
    sx = sx || []
    sx.push({
      [`& .${dialogClasses.scrollPaper}`]: {
        alignItems: 'baseline',
        paddingTop: positionPaddingTop,
      }
    })
  }

  const renderActions = (actions) => {

    return actions.map(a=>{
      if (a.value === 'submit') {
        return <DialogActionCloseButton key={a.value} onClick={(e)=>handleClose(e, 'submit')} color={a.color??'primary'} variant="contained">{a.title}</DialogActionCloseButton>
      } 
      if (a.value === 'cancel') {
        return <DialogActionCloseButton key={a.value} onClick={(e)=>handleClose(e, 'cancelClick')} color={a.color??'greyDark'} variant="outlined">{a.title}</DialogActionCloseButton>
      }
      else {
        throw new Error(`action not implemented ${a.value}`)
      }
    })

  }

  return (
    <Dialog 
      onClose={handleClose} 
      open={open} 
      fullWidth={fullWidth} 
      maxWidth={maxWidth}
      classes={dialogStyleClasses}  
      fullScreen={fullScreen}
      TransitionComponent={TransitionComponent}
      PaperProps={{
        style: { borderRadius: 12 },
        ...PaperProps,
        //@ts-ignore
        sx: [styles.paper, responsive?theme=>({[theme.breakpoints.down('md')]:{width:'100%'}}):null, PaperProps?.sx||null]
      }}
      sx={sx}
      container={container}
      BackdropProps={BackdropProps}
      style={style}
      scroll={scroll}
      disableEnforceFocus={disableEnforceFocus}
      >
      {showDefaultTitleElem && (
        <>
        <DialogTitle className={titleClasses.join(' ')}>
          {entity !== undefined && entity !== null && entity.type && entity.name && (
            <div>
              <EntityName type={entity.type} name={entity.name} />
            </div>
          )}
          <Box display="flex" position="relative">
            <Box className={classes.title} flexGrow={1} style={dialogTitleStyle}>{title}</Box>
            {(headerContentEnd) && <Box>{headerContentEnd}</Box>}
            {(showTitleCloseButton) && renderTitleCloseButton('-5px')}
          </Box>
          {subtitle && 
            <Box color="text.secondary" textAlign="left" mt={1} width="100%">
              {isString(subtitle) 
                ? <Typography fontWeight="400">{subtitle}</Typography>
                : subtitle
              }
            </Box>
          }
        </DialogTitle>
        {hasDividerAfterTitle && (
          <Divider />
        )}
        </>
      )}
      {(showIncentiveTitle || showFullScreenCloseButton) && (
        <DialogTitle>
          {showFullScreenCloseButton === true && (
                <IconButton onClick={handleCloseWithoutReason} size="small" style={{color: 'rgb(161 161 161 / 27%)'}}>
                  <CloseIcon />
                </IconButton>
          )}
          {incentiveTitle ? (
            <Box textAlign="center" pt={3} mb={2}>
              <Typography variant="h2" className={classes.incentiveTitle}>{incentiveTitle}</Typography>
              {showTitleCloseButton && renderTitleCloseButton('8px')}
            </Box>
          ): <Box mb={2}></Box>}
          {incentiveSubtitle && (
            <Box textAlign="center">
              {/** @ts-ignore */}
              <Typography className={classes.incentiveSubtitle}><MultilineText>{incentiveSubtitle}</MultilineText></Typography>
            </Box>
          )}
        </DialogTitle>
      )}
      <DialogContent className={contentClasses.join(' ')} style={noScroll?{ overflow: "hidden" }:null}>
        {showCloseButton && renderTitleCloseButton('8px')}
        {/** @ts-ignore - reason: manuel children callable check */}
        {children && isFunction(children) ? children() : children}
      </DialogContent>
      {hideActions !== true && (
        <>
          {actionsNoDivider != true && <Divider />}
          <DialogActions className={classes.dialogActions} sx={actionsStyle}>
            {actionsComponent ? actionsComponent(handleClose):(
              actions
                ? renderActions(actions)
                : <DialogActionCloseButton onClick={(e)=>handleClose(e, undefined)} />
            )}
          </DialogActions>
        </>
      )}
      {fullWidthDoneButton === true && (
        <DialogActions className={classes.dialogActions}>
          <Button color="primary" variant="contained" fullWidth onClick={handleCloseWithoutReason}>{doneButtonText??'Done'}</Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

SimpleDialog.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  children: PropTypes.node,
}

/**
 * 
 * @deprecated use useDialogControl instead
 */
export const useDialogOpen = () => {

  const [open, setOpen] = useState(false)

  const openDialog = () => {
    setOpen(true)
  }

  const closeDialog = () => {
    setOpen(false)
  }

  return [open, openDialog, closeDialog];
};

export interface UseDialogControlReturn {
  // eslint-disable-next-line no-unused-vars
  openDialog: (openConfig?: any)=>void
  dialogProps?: SimpleDialogProps & {}
}

//gives open and onclose properties usable for SimpleDialog
export const useDialogControl = (config?:any):UseDialogControlReturn => {

 const { onBeforeClose, onAfterClose, openParam, onOpen } = config || {}

  const router = useRouter()

  const routerUtils = useRouterUtils()

  const initialOpen = orElse(config?.open,false)

  const [open, setOpen] = useState(initialOpen)
  
  const wasOpen = useRef(initialOpen)

  const [additionalDialogProps, setAdditionalDialogProps] = useState<any>()

  useEffect(()=> {
    if (!router.isReady) {
      return;
    }

    if (openParam && isHavingValue(router.query[openParam]) === true) {
      setOpen(true)
    }

  }, [router.isReady, router.query])

  useEffect(()=>{
    if (open === true && wasOpen.current === false) {
      wasOpen.current = true
      onOpen?.()
    }

    if (open === false && wasOpen.current === true) {
      wasOpen.current === false
      if (onAfterClose) {
        onAfterClose()
      }
    }

  },[open])

  const openDialog = (openConfig?:any) => {
    
    const { props } = openConfig || {}

    if (props) {
      setAdditionalDialogProps(props)
    }

    setOpen(true)

    if (openParam) {
      routerUtils.pushAdditionalQueryParams({
        [openParam]: true
      })
    }
  }

  const closeDialog = async () => {
    if (onBeforeClose) {
      onBeforeClose().then((result)=>{
        if (result !== true) {
          return;
        }
        performClose()
      })
      return;
    }

    performClose()
  };

  /**
   * actually closing if everything is ok
   */
  const performClose = () => {
    setOpen(false)

    if (additionalDialogProps) {
      setAdditionalDialogProps(null)
    }

    if (openParam && isHavingValue(router.query[openParam]) === true) {
      routerUtils.deleteQueryParam(openParam)
    }

  }

  let dialogProps = {open, onClose: closeDialog}
  if (additionalDialogProps) {
    dialogProps = {
      ...dialogProps,
      ...additionalDialogProps
    }
  }

  return {dialogProps, openDialog}
};

export interface SimpleDialogButtonProps {
  // eslint-disable-next-line no-unused-vars
  renderToggleButton?: (onClick: ()=>void, toggleButtonText?: ReactNode) => ReactNode
  toggleButtonText?: ReactNode
  toggleButtonProps?: ButtonProps
  dialogProps?: any
  onClosed?: () => void
  onBeforeOpen?: () => void
  onBeforeClose?: (event: any, reason: "backdropClick" | "escapeKeyDown" | "submit")=>Promise<void>
  // eslint-disable-next-line no-unused-vars
  children?: ReactNode | ((open:boolean, wasOpen:boolean, onClose:()=>void)=>ReactNode)
}

/**
 * contains button and dialog
 */
export const SimpleDialogButton: FunctionComponent<SimpleDialogButtonProps> = ({children, renderToggleButton, toggleButtonText, toggleButtonProps, dialogProps, onClosed, onBeforeOpen, onBeforeClose}) => {

  const [open, setOpen] = useState(false)

  const wasOpen = useRef(false)

  toggleButtonText = toggleButtonText || 'Open'

  const handleBtnClick = () => {
    if (onBeforeOpen) {
      onBeforeOpen()
    }
    wasOpen.current = true
    setOpen(true)
  }

  const handleDialogClose = (event, reason) => {

    const executeClose = () => {
      setOpen(false)
      if (onClosed) {
        onClosed()
      }
    }

    if (onBeforeClose) {
      onBeforeClose?.(event, reason).then(()=>{
        executeClose()
      })
    } else {
      executeClose()
    }
  }

  const handleDialogCloseBackwardsCompatible = () => {
    handleDialogClose(undefined, undefined)
  }

  const toggleButtonEffective = renderToggleButton ? renderToggleButton(handleBtnClick, toggleButtonText) : <Button
    onClick={handleBtnClick}
    {...toggleButtonProps}
    >{toggleButtonText}</Button>

  return (
    <>
      {toggleButtonEffective}
      <SimpleDialog 
        open={open}
        onClose={handleDialogClose}
        {...dialogProps}
        >
        {children instanceof Function ? (children(open, wasOpen.current, handleDialogCloseBackwardsCompatible)??<></>) : children}
      </SimpleDialog>
    </>
  )

}