import { useIsMounted } from "modules/picasso-ui/form/useIsMounted"
import { useEffect, useState } from "react"

export const DeferVisibility = ({timeoutMs = 1000, children}) => {

    const [render, setRender] = useState<boolean>()

    const isMounted = useIsMounted()

    useEffect(()=>{
        if (render) { return; }

        setTimeout(()=>{
            if (!isMounted()) { return; }

            setRender(true)
        }, timeoutMs)

    }, [])

    if (!render) {
       return null
    }

    return children

}