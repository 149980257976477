import makeStyles from '@mui/styles/makeStyles';
import { FC, PropsWithChildren } from 'react';

export const EntityName: FC<PropsWithChildren<{type?:string, name?: string}>> = ({type, name, children}) => {
    
    const classes = useStyles();

    return (
        <span className={classes.root}>
            <span className={classes.type}>{type}</span>
            <span className={classes.name}>{children??name}</span>
        </span>  
    )
}

const useStyles = makeStyles(() => ({
    root: {
        fontSize: '0.8em',
        padding: '2px',
    },
    type: {
        color: '#000',
        fontWeight: '450'
    },
    name: {
        color: '#98a3a7',
        marginLeft: '8px'
    },
}));
